// source: sgn/distribution/v1/distribution.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var gogoproto_gogo_pb = require('../../../gogoproto/gogo_pb.js');
goog.object.extend(proto, gogoproto_gogo_pb);
var cosmos_base_v1beta1_coin_pb = require('../../../cosmos/base/v1beta1/coin_pb.js');
goog.object.extend(proto, cosmos_base_v1beta1_coin_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var sgn_common_v1_common_pb = require('../../../sgn/common/v1/common_pb.js');
goog.object.extend(proto, sgn_common_v1_common_pb);
goog.exportSymbol('proto.sgn.distribution.v1.ClaimableFeesInfo', null, global);
goog.exportSymbol('proto.sgn.distribution.v1.CommunityPoolSpendProposal', null, global);
goog.exportSymbol('proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit', null, global);
goog.exportSymbol('proto.sgn.distribution.v1.DelegationDelegatorReward', null, global);
goog.exportSymbol('proto.sgn.distribution.v1.DelegatorStartingInfo', null, global);
goog.exportSymbol('proto.sgn.distribution.v1.FeePool', null, global);
goog.exportSymbol('proto.sgn.distribution.v1.Params', null, global);
goog.exportSymbol('proto.sgn.distribution.v1.StakingRewardClaimInfo', null, global);
goog.exportSymbol('proto.sgn.distribution.v1.StakingRewardInfo', null, global);
goog.exportSymbol('proto.sgn.distribution.v1.StakingRewardOnChain', null, global);
goog.exportSymbol('proto.sgn.distribution.v1.ValidatorAccumulatedCommission', null, global);
goog.exportSymbol('proto.sgn.distribution.v1.ValidatorCurrentRewards', null, global);
goog.exportSymbol('proto.sgn.distribution.v1.ValidatorHistoricalRewards', null, global);
goog.exportSymbol('proto.sgn.distribution.v1.ValidatorOutstandingRewards', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.distribution.v1.Params = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.distribution.v1.Params, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.distribution.v1.Params.displayName = 'proto.sgn.distribution.v1.Params';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.distribution.v1.ValidatorHistoricalRewards = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.distribution.v1.ValidatorHistoricalRewards.repeatedFields_, null);
};
goog.inherits(proto.sgn.distribution.v1.ValidatorHistoricalRewards, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.distribution.v1.ValidatorHistoricalRewards.displayName = 'proto.sgn.distribution.v1.ValidatorHistoricalRewards';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.distribution.v1.ValidatorCurrentRewards = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.distribution.v1.ValidatorCurrentRewards.repeatedFields_, null);
};
goog.inherits(proto.sgn.distribution.v1.ValidatorCurrentRewards, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.distribution.v1.ValidatorCurrentRewards.displayName = 'proto.sgn.distribution.v1.ValidatorCurrentRewards';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.distribution.v1.ValidatorAccumulatedCommission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.distribution.v1.ValidatorAccumulatedCommission.repeatedFields_, null);
};
goog.inherits(proto.sgn.distribution.v1.ValidatorAccumulatedCommission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.distribution.v1.ValidatorAccumulatedCommission.displayName = 'proto.sgn.distribution.v1.ValidatorAccumulatedCommission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.distribution.v1.ValidatorOutstandingRewards = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.distribution.v1.ValidatorOutstandingRewards.repeatedFields_, null);
};
goog.inherits(proto.sgn.distribution.v1.ValidatorOutstandingRewards, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.distribution.v1.ValidatorOutstandingRewards.displayName = 'proto.sgn.distribution.v1.ValidatorOutstandingRewards';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.distribution.v1.FeePool = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.distribution.v1.FeePool.repeatedFields_, null);
};
goog.inherits(proto.sgn.distribution.v1.FeePool, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.distribution.v1.FeePool.displayName = 'proto.sgn.distribution.v1.FeePool';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.distribution.v1.CommunityPoolSpendProposal.repeatedFields_, null);
};
goog.inherits(proto.sgn.distribution.v1.CommunityPoolSpendProposal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.distribution.v1.CommunityPoolSpendProposal.displayName = 'proto.sgn.distribution.v1.CommunityPoolSpendProposal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.distribution.v1.DelegatorStartingInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.distribution.v1.DelegatorStartingInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.distribution.v1.DelegatorStartingInfo.displayName = 'proto.sgn.distribution.v1.DelegatorStartingInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.distribution.v1.DelegationDelegatorReward = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.distribution.v1.DelegationDelegatorReward.repeatedFields_, null);
};
goog.inherits(proto.sgn.distribution.v1.DelegationDelegatorReward, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.distribution.v1.DelegationDelegatorReward.displayName = 'proto.sgn.distribution.v1.DelegationDelegatorReward';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.distribution.v1.StakingRewardInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.distribution.v1.StakingRewardInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.distribution.v1.StakingRewardInfo.displayName = 'proto.sgn.distribution.v1.StakingRewardInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.distribution.v1.StakingRewardClaimInfo.repeatedFields_, null);
};
goog.inherits(proto.sgn.distribution.v1.StakingRewardClaimInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.distribution.v1.StakingRewardClaimInfo.displayName = 'proto.sgn.distribution.v1.StakingRewardClaimInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.distribution.v1.ClaimableFeesInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.distribution.v1.ClaimableFeesInfo.repeatedFields_, null);
};
goog.inherits(proto.sgn.distribution.v1.ClaimableFeesInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.distribution.v1.ClaimableFeesInfo.displayName = 'proto.sgn.distribution.v1.ClaimableFeesInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.distribution.v1.StakingRewardOnChain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.distribution.v1.StakingRewardOnChain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.distribution.v1.StakingRewardOnChain.displayName = 'proto.sgn.distribution.v1.StakingRewardOnChain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit.displayName = 'proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.distribution.v1.Params.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.distribution.v1.Params.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.distribution.v1.Params} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.Params.toObject = function(includeInstance, msg) {
  var f, obj = {
    communityTax: jspb.Message.getFieldWithDefault(msg, 1, ""),
    baseProposerReward: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bonusProposerReward: jspb.Message.getFieldWithDefault(msg, 3, ""),
    withdrawAddrEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    claimCooldown: (f = msg.getClaimCooldown()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    rewardContract: (f = msg.getRewardContract()) && sgn_common_v1_common_pb.ContractInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.distribution.v1.Params}
 */
proto.sgn.distribution.v1.Params.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.distribution.v1.Params;
  return proto.sgn.distribution.v1.Params.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.distribution.v1.Params} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.distribution.v1.Params}
 */
proto.sgn.distribution.v1.Params.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityTax(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseProposerReward(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBonusProposerReward(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWithdrawAddrEnabled(value);
      break;
    case 5:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setClaimCooldown(value);
      break;
    case 6:
      var value = new sgn_common_v1_common_pb.ContractInfo;
      reader.readMessage(value,sgn_common_v1_common_pb.ContractInfo.deserializeBinaryFromReader);
      msg.setRewardContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.distribution.v1.Params.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.distribution.v1.Params.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.distribution.v1.Params} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.Params.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommunityTax();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBaseProposerReward();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBonusProposerReward();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWithdrawAddrEnabled();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getClaimCooldown();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getRewardContract();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      sgn_common_v1_common_pb.ContractInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string community_tax = 1;
 * @return {string}
 */
proto.sgn.distribution.v1.Params.prototype.getCommunityTax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.distribution.v1.Params} returns this
 */
proto.sgn.distribution.v1.Params.prototype.setCommunityTax = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string base_proposer_reward = 2;
 * @return {string}
 */
proto.sgn.distribution.v1.Params.prototype.getBaseProposerReward = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.distribution.v1.Params} returns this
 */
proto.sgn.distribution.v1.Params.prototype.setBaseProposerReward = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string bonus_proposer_reward = 3;
 * @return {string}
 */
proto.sgn.distribution.v1.Params.prototype.getBonusProposerReward = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.distribution.v1.Params} returns this
 */
proto.sgn.distribution.v1.Params.prototype.setBonusProposerReward = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool withdraw_addr_enabled = 4;
 * @return {boolean}
 */
proto.sgn.distribution.v1.Params.prototype.getWithdrawAddrEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sgn.distribution.v1.Params} returns this
 */
proto.sgn.distribution.v1.Params.prototype.setWithdrawAddrEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional google.protobuf.Duration claim_cooldown = 5;
 * @return {?proto.google.protobuf.Duration}
 */
proto.sgn.distribution.v1.Params.prototype.getClaimCooldown = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 5));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.sgn.distribution.v1.Params} returns this
*/
proto.sgn.distribution.v1.Params.prototype.setClaimCooldown = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.distribution.v1.Params} returns this
 */
proto.sgn.distribution.v1.Params.prototype.clearClaimCooldown = function() {
  return this.setClaimCooldown(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.distribution.v1.Params.prototype.hasClaimCooldown = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional sgn.common.v1.ContractInfo reward_contract = 6;
 * @return {?proto.sgn.common.v1.ContractInfo}
 */
proto.sgn.distribution.v1.Params.prototype.getRewardContract = function() {
  return /** @type{?proto.sgn.common.v1.ContractInfo} */ (
    jspb.Message.getWrapperField(this, sgn_common_v1_common_pb.ContractInfo, 6));
};


/**
 * @param {?proto.sgn.common.v1.ContractInfo|undefined} value
 * @return {!proto.sgn.distribution.v1.Params} returns this
*/
proto.sgn.distribution.v1.Params.prototype.setRewardContract = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.distribution.v1.Params} returns this
 */
proto.sgn.distribution.v1.Params.prototype.clearRewardContract = function() {
  return this.setRewardContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.distribution.v1.Params.prototype.hasRewardContract = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.distribution.v1.ValidatorHistoricalRewards.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.distribution.v1.ValidatorHistoricalRewards.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.distribution.v1.ValidatorHistoricalRewards.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.distribution.v1.ValidatorHistoricalRewards} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.ValidatorHistoricalRewards.toObject = function(includeInstance, msg) {
  var f, obj = {
    cumulativeRewardRatioList: jspb.Message.toObjectList(msg.getCumulativeRewardRatioList(),
    cosmos_base_v1beta1_coin_pb.DecCoin.toObject, includeInstance),
    referenceCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.distribution.v1.ValidatorHistoricalRewards}
 */
proto.sgn.distribution.v1.ValidatorHistoricalRewards.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.distribution.v1.ValidatorHistoricalRewards;
  return proto.sgn.distribution.v1.ValidatorHistoricalRewards.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.distribution.v1.ValidatorHistoricalRewards} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.distribution.v1.ValidatorHistoricalRewards}
 */
proto.sgn.distribution.v1.ValidatorHistoricalRewards.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new cosmos_base_v1beta1_coin_pb.DecCoin;
      reader.readMessage(value,cosmos_base_v1beta1_coin_pb.DecCoin.deserializeBinaryFromReader);
      msg.addCumulativeRewardRatio(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReferenceCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.distribution.v1.ValidatorHistoricalRewards.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.distribution.v1.ValidatorHistoricalRewards.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.distribution.v1.ValidatorHistoricalRewards} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.ValidatorHistoricalRewards.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCumulativeRewardRatioList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      cosmos_base_v1beta1_coin_pb.DecCoin.serializeBinaryToWriter
    );
  }
  f = message.getReferenceCount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * repeated cosmos.base.v1beta1.DecCoin cumulative_reward_ratio = 1;
 * @return {!Array<!proto.cosmos.base.v1beta1.DecCoin>}
 */
proto.sgn.distribution.v1.ValidatorHistoricalRewards.prototype.getCumulativeRewardRatioList = function() {
  return /** @type{!Array<!proto.cosmos.base.v1beta1.DecCoin>} */ (
    jspb.Message.getRepeatedWrapperField(this, cosmos_base_v1beta1_coin_pb.DecCoin, 1));
};


/**
 * @param {!Array<!proto.cosmos.base.v1beta1.DecCoin>} value
 * @return {!proto.sgn.distribution.v1.ValidatorHistoricalRewards} returns this
*/
proto.sgn.distribution.v1.ValidatorHistoricalRewards.prototype.setCumulativeRewardRatioList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cosmos.base.v1beta1.DecCoin=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cosmos.base.v1beta1.DecCoin}
 */
proto.sgn.distribution.v1.ValidatorHistoricalRewards.prototype.addCumulativeRewardRatio = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cosmos.base.v1beta1.DecCoin, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.distribution.v1.ValidatorHistoricalRewards} returns this
 */
proto.sgn.distribution.v1.ValidatorHistoricalRewards.prototype.clearCumulativeRewardRatioList = function() {
  return this.setCumulativeRewardRatioList([]);
};


/**
 * optional uint32 reference_count = 2;
 * @return {number}
 */
proto.sgn.distribution.v1.ValidatorHistoricalRewards.prototype.getReferenceCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.distribution.v1.ValidatorHistoricalRewards} returns this
 */
proto.sgn.distribution.v1.ValidatorHistoricalRewards.prototype.setReferenceCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.distribution.v1.ValidatorCurrentRewards.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.distribution.v1.ValidatorCurrentRewards.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.distribution.v1.ValidatorCurrentRewards.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.distribution.v1.ValidatorCurrentRewards} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.ValidatorCurrentRewards.toObject = function(includeInstance, msg) {
  var f, obj = {
    rewardsList: jspb.Message.toObjectList(msg.getRewardsList(),
    cosmos_base_v1beta1_coin_pb.DecCoin.toObject, includeInstance),
    period: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.distribution.v1.ValidatorCurrentRewards}
 */
proto.sgn.distribution.v1.ValidatorCurrentRewards.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.distribution.v1.ValidatorCurrentRewards;
  return proto.sgn.distribution.v1.ValidatorCurrentRewards.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.distribution.v1.ValidatorCurrentRewards} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.distribution.v1.ValidatorCurrentRewards}
 */
proto.sgn.distribution.v1.ValidatorCurrentRewards.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new cosmos_base_v1beta1_coin_pb.DecCoin;
      reader.readMessage(value,cosmos_base_v1beta1_coin_pb.DecCoin.deserializeBinaryFromReader);
      msg.addRewards(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.distribution.v1.ValidatorCurrentRewards.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.distribution.v1.ValidatorCurrentRewards.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.distribution.v1.ValidatorCurrentRewards} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.ValidatorCurrentRewards.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRewardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      cosmos_base_v1beta1_coin_pb.DecCoin.serializeBinaryToWriter
    );
  }
  f = message.getPeriod();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * repeated cosmos.base.v1beta1.DecCoin rewards = 1;
 * @return {!Array<!proto.cosmos.base.v1beta1.DecCoin>}
 */
proto.sgn.distribution.v1.ValidatorCurrentRewards.prototype.getRewardsList = function() {
  return /** @type{!Array<!proto.cosmos.base.v1beta1.DecCoin>} */ (
    jspb.Message.getRepeatedWrapperField(this, cosmos_base_v1beta1_coin_pb.DecCoin, 1));
};


/**
 * @param {!Array<!proto.cosmos.base.v1beta1.DecCoin>} value
 * @return {!proto.sgn.distribution.v1.ValidatorCurrentRewards} returns this
*/
proto.sgn.distribution.v1.ValidatorCurrentRewards.prototype.setRewardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cosmos.base.v1beta1.DecCoin=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cosmos.base.v1beta1.DecCoin}
 */
proto.sgn.distribution.v1.ValidatorCurrentRewards.prototype.addRewards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cosmos.base.v1beta1.DecCoin, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.distribution.v1.ValidatorCurrentRewards} returns this
 */
proto.sgn.distribution.v1.ValidatorCurrentRewards.prototype.clearRewardsList = function() {
  return this.setRewardsList([]);
};


/**
 * optional uint64 period = 2;
 * @return {number}
 */
proto.sgn.distribution.v1.ValidatorCurrentRewards.prototype.getPeriod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.distribution.v1.ValidatorCurrentRewards} returns this
 */
proto.sgn.distribution.v1.ValidatorCurrentRewards.prototype.setPeriod = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.distribution.v1.ValidatorAccumulatedCommission.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.distribution.v1.ValidatorAccumulatedCommission.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.distribution.v1.ValidatorAccumulatedCommission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.distribution.v1.ValidatorAccumulatedCommission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.ValidatorAccumulatedCommission.toObject = function(includeInstance, msg) {
  var f, obj = {
    commissionList: jspb.Message.toObjectList(msg.getCommissionList(),
    cosmos_base_v1beta1_coin_pb.DecCoin.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.distribution.v1.ValidatorAccumulatedCommission}
 */
proto.sgn.distribution.v1.ValidatorAccumulatedCommission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.distribution.v1.ValidatorAccumulatedCommission;
  return proto.sgn.distribution.v1.ValidatorAccumulatedCommission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.distribution.v1.ValidatorAccumulatedCommission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.distribution.v1.ValidatorAccumulatedCommission}
 */
proto.sgn.distribution.v1.ValidatorAccumulatedCommission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new cosmos_base_v1beta1_coin_pb.DecCoin;
      reader.readMessage(value,cosmos_base_v1beta1_coin_pb.DecCoin.deserializeBinaryFromReader);
      msg.addCommission(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.distribution.v1.ValidatorAccumulatedCommission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.distribution.v1.ValidatorAccumulatedCommission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.distribution.v1.ValidatorAccumulatedCommission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.ValidatorAccumulatedCommission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommissionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      cosmos_base_v1beta1_coin_pb.DecCoin.serializeBinaryToWriter
    );
  }
};


/**
 * repeated cosmos.base.v1beta1.DecCoin commission = 1;
 * @return {!Array<!proto.cosmos.base.v1beta1.DecCoin>}
 */
proto.sgn.distribution.v1.ValidatorAccumulatedCommission.prototype.getCommissionList = function() {
  return /** @type{!Array<!proto.cosmos.base.v1beta1.DecCoin>} */ (
    jspb.Message.getRepeatedWrapperField(this, cosmos_base_v1beta1_coin_pb.DecCoin, 1));
};


/**
 * @param {!Array<!proto.cosmos.base.v1beta1.DecCoin>} value
 * @return {!proto.sgn.distribution.v1.ValidatorAccumulatedCommission} returns this
*/
proto.sgn.distribution.v1.ValidatorAccumulatedCommission.prototype.setCommissionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cosmos.base.v1beta1.DecCoin=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cosmos.base.v1beta1.DecCoin}
 */
proto.sgn.distribution.v1.ValidatorAccumulatedCommission.prototype.addCommission = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cosmos.base.v1beta1.DecCoin, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.distribution.v1.ValidatorAccumulatedCommission} returns this
 */
proto.sgn.distribution.v1.ValidatorAccumulatedCommission.prototype.clearCommissionList = function() {
  return this.setCommissionList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.distribution.v1.ValidatorOutstandingRewards.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.distribution.v1.ValidatorOutstandingRewards.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.distribution.v1.ValidatorOutstandingRewards.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.distribution.v1.ValidatorOutstandingRewards} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.ValidatorOutstandingRewards.toObject = function(includeInstance, msg) {
  var f, obj = {
    rewardsList: jspb.Message.toObjectList(msg.getRewardsList(),
    cosmos_base_v1beta1_coin_pb.DecCoin.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.distribution.v1.ValidatorOutstandingRewards}
 */
proto.sgn.distribution.v1.ValidatorOutstandingRewards.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.distribution.v1.ValidatorOutstandingRewards;
  return proto.sgn.distribution.v1.ValidatorOutstandingRewards.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.distribution.v1.ValidatorOutstandingRewards} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.distribution.v1.ValidatorOutstandingRewards}
 */
proto.sgn.distribution.v1.ValidatorOutstandingRewards.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new cosmos_base_v1beta1_coin_pb.DecCoin;
      reader.readMessage(value,cosmos_base_v1beta1_coin_pb.DecCoin.deserializeBinaryFromReader);
      msg.addRewards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.distribution.v1.ValidatorOutstandingRewards.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.distribution.v1.ValidatorOutstandingRewards.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.distribution.v1.ValidatorOutstandingRewards} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.ValidatorOutstandingRewards.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRewardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      cosmos_base_v1beta1_coin_pb.DecCoin.serializeBinaryToWriter
    );
  }
};


/**
 * repeated cosmos.base.v1beta1.DecCoin rewards = 1;
 * @return {!Array<!proto.cosmos.base.v1beta1.DecCoin>}
 */
proto.sgn.distribution.v1.ValidatorOutstandingRewards.prototype.getRewardsList = function() {
  return /** @type{!Array<!proto.cosmos.base.v1beta1.DecCoin>} */ (
    jspb.Message.getRepeatedWrapperField(this, cosmos_base_v1beta1_coin_pb.DecCoin, 1));
};


/**
 * @param {!Array<!proto.cosmos.base.v1beta1.DecCoin>} value
 * @return {!proto.sgn.distribution.v1.ValidatorOutstandingRewards} returns this
*/
proto.sgn.distribution.v1.ValidatorOutstandingRewards.prototype.setRewardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cosmos.base.v1beta1.DecCoin=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cosmos.base.v1beta1.DecCoin}
 */
proto.sgn.distribution.v1.ValidatorOutstandingRewards.prototype.addRewards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cosmos.base.v1beta1.DecCoin, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.distribution.v1.ValidatorOutstandingRewards} returns this
 */
proto.sgn.distribution.v1.ValidatorOutstandingRewards.prototype.clearRewardsList = function() {
  return this.setRewardsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.distribution.v1.FeePool.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.distribution.v1.FeePool.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.distribution.v1.FeePool.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.distribution.v1.FeePool} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.FeePool.toObject = function(includeInstance, msg) {
  var f, obj = {
    communityPoolList: jspb.Message.toObjectList(msg.getCommunityPoolList(),
    cosmos_base_v1beta1_coin_pb.DecCoin.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.distribution.v1.FeePool}
 */
proto.sgn.distribution.v1.FeePool.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.distribution.v1.FeePool;
  return proto.sgn.distribution.v1.FeePool.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.distribution.v1.FeePool} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.distribution.v1.FeePool}
 */
proto.sgn.distribution.v1.FeePool.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new cosmos_base_v1beta1_coin_pb.DecCoin;
      reader.readMessage(value,cosmos_base_v1beta1_coin_pb.DecCoin.deserializeBinaryFromReader);
      msg.addCommunityPool(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.distribution.v1.FeePool.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.distribution.v1.FeePool.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.distribution.v1.FeePool} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.FeePool.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommunityPoolList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      cosmos_base_v1beta1_coin_pb.DecCoin.serializeBinaryToWriter
    );
  }
};


/**
 * repeated cosmos.base.v1beta1.DecCoin community_pool = 1;
 * @return {!Array<!proto.cosmos.base.v1beta1.DecCoin>}
 */
proto.sgn.distribution.v1.FeePool.prototype.getCommunityPoolList = function() {
  return /** @type{!Array<!proto.cosmos.base.v1beta1.DecCoin>} */ (
    jspb.Message.getRepeatedWrapperField(this, cosmos_base_v1beta1_coin_pb.DecCoin, 1));
};


/**
 * @param {!Array<!proto.cosmos.base.v1beta1.DecCoin>} value
 * @return {!proto.sgn.distribution.v1.FeePool} returns this
*/
proto.sgn.distribution.v1.FeePool.prototype.setCommunityPoolList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cosmos.base.v1beta1.DecCoin=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cosmos.base.v1beta1.DecCoin}
 */
proto.sgn.distribution.v1.FeePool.prototype.addCommunityPool = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cosmos.base.v1beta1.DecCoin, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.distribution.v1.FeePool} returns this
 */
proto.sgn.distribution.v1.FeePool.prototype.clearCommunityPoolList = function() {
  return this.setCommunityPoolList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposal.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposal.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.distribution.v1.CommunityPoolSpendProposal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.distribution.v1.CommunityPoolSpendProposal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposal.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    recipient: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amountList: jspb.Message.toObjectList(msg.getAmountList(),
    cosmos_base_v1beta1_coin_pb.Coin.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.distribution.v1.CommunityPoolSpendProposal}
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.distribution.v1.CommunityPoolSpendProposal;
  return proto.sgn.distribution.v1.CommunityPoolSpendProposal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.distribution.v1.CommunityPoolSpendProposal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.distribution.v1.CommunityPoolSpendProposal}
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecipient(value);
      break;
    case 4:
      var value = new cosmos_base_v1beta1_coin_pb.Coin;
      reader.readMessage(value,cosmos_base_v1beta1_coin_pb.Coin.deserializeBinaryFromReader);
      msg.addAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.distribution.v1.CommunityPoolSpendProposal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.distribution.v1.CommunityPoolSpendProposal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRecipient();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmountList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      cosmos_base_v1beta1_coin_pb.Coin.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposal.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.distribution.v1.CommunityPoolSpendProposal} returns this
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposal.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposal.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.distribution.v1.CommunityPoolSpendProposal} returns this
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposal.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string recipient = 3;
 * @return {string}
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposal.prototype.getRecipient = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.distribution.v1.CommunityPoolSpendProposal} returns this
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposal.prototype.setRecipient = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated cosmos.base.v1beta1.Coin amount = 4;
 * @return {!Array<!proto.cosmos.base.v1beta1.Coin>}
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposal.prototype.getAmountList = function() {
  return /** @type{!Array<!proto.cosmos.base.v1beta1.Coin>} */ (
    jspb.Message.getRepeatedWrapperField(this, cosmos_base_v1beta1_coin_pb.Coin, 4));
};


/**
 * @param {!Array<!proto.cosmos.base.v1beta1.Coin>} value
 * @return {!proto.sgn.distribution.v1.CommunityPoolSpendProposal} returns this
*/
proto.sgn.distribution.v1.CommunityPoolSpendProposal.prototype.setAmountList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cosmos.base.v1beta1.Coin=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cosmos.base.v1beta1.Coin}
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposal.prototype.addAmount = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cosmos.base.v1beta1.Coin, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.distribution.v1.CommunityPoolSpendProposal} returns this
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposal.prototype.clearAmountList = function() {
  return this.setAmountList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.distribution.v1.DelegatorStartingInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.distribution.v1.DelegatorStartingInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.distribution.v1.DelegatorStartingInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.DelegatorStartingInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    previousPeriod: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stake: jspb.Message.getFieldWithDefault(msg, 2, ""),
    height: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.distribution.v1.DelegatorStartingInfo}
 */
proto.sgn.distribution.v1.DelegatorStartingInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.distribution.v1.DelegatorStartingInfo;
  return proto.sgn.distribution.v1.DelegatorStartingInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.distribution.v1.DelegatorStartingInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.distribution.v1.DelegatorStartingInfo}
 */
proto.sgn.distribution.v1.DelegatorStartingInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPreviousPeriod(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStake(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.distribution.v1.DelegatorStartingInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.distribution.v1.DelegatorStartingInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.distribution.v1.DelegatorStartingInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.DelegatorStartingInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreviousPeriod();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getStake();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional uint64 previous_period = 1;
 * @return {number}
 */
proto.sgn.distribution.v1.DelegatorStartingInfo.prototype.getPreviousPeriod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.distribution.v1.DelegatorStartingInfo} returns this
 */
proto.sgn.distribution.v1.DelegatorStartingInfo.prototype.setPreviousPeriod = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string stake = 2;
 * @return {string}
 */
proto.sgn.distribution.v1.DelegatorStartingInfo.prototype.getStake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.distribution.v1.DelegatorStartingInfo} returns this
 */
proto.sgn.distribution.v1.DelegatorStartingInfo.prototype.setStake = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 height = 3;
 * @return {number}
 */
proto.sgn.distribution.v1.DelegatorStartingInfo.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.distribution.v1.DelegatorStartingInfo} returns this
 */
proto.sgn.distribution.v1.DelegatorStartingInfo.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.distribution.v1.DelegationDelegatorReward.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.distribution.v1.DelegationDelegatorReward.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.distribution.v1.DelegationDelegatorReward.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.distribution.v1.DelegationDelegatorReward} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.DelegationDelegatorReward.toObject = function(includeInstance, msg) {
  var f, obj = {
    validatorAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rewardList: jspb.Message.toObjectList(msg.getRewardList(),
    cosmos_base_v1beta1_coin_pb.DecCoin.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.distribution.v1.DelegationDelegatorReward}
 */
proto.sgn.distribution.v1.DelegationDelegatorReward.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.distribution.v1.DelegationDelegatorReward;
  return proto.sgn.distribution.v1.DelegationDelegatorReward.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.distribution.v1.DelegationDelegatorReward} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.distribution.v1.DelegationDelegatorReward}
 */
proto.sgn.distribution.v1.DelegationDelegatorReward.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidatorAddress(value);
      break;
    case 2:
      var value = new cosmos_base_v1beta1_coin_pb.DecCoin;
      reader.readMessage(value,cosmos_base_v1beta1_coin_pb.DecCoin.deserializeBinaryFromReader);
      msg.addReward(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.distribution.v1.DelegationDelegatorReward.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.distribution.v1.DelegationDelegatorReward.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.distribution.v1.DelegationDelegatorReward} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.DelegationDelegatorReward.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValidatorAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRewardList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      cosmos_base_v1beta1_coin_pb.DecCoin.serializeBinaryToWriter
    );
  }
};


/**
 * optional string validator_address = 1;
 * @return {string}
 */
proto.sgn.distribution.v1.DelegationDelegatorReward.prototype.getValidatorAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.distribution.v1.DelegationDelegatorReward} returns this
 */
proto.sgn.distribution.v1.DelegationDelegatorReward.prototype.setValidatorAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated cosmos.base.v1beta1.DecCoin reward = 2;
 * @return {!Array<!proto.cosmos.base.v1beta1.DecCoin>}
 */
proto.sgn.distribution.v1.DelegationDelegatorReward.prototype.getRewardList = function() {
  return /** @type{!Array<!proto.cosmos.base.v1beta1.DecCoin>} */ (
    jspb.Message.getRepeatedWrapperField(this, cosmos_base_v1beta1_coin_pb.DecCoin, 2));
};


/**
 * @param {!Array<!proto.cosmos.base.v1beta1.DecCoin>} value
 * @return {!proto.sgn.distribution.v1.DelegationDelegatorReward} returns this
*/
proto.sgn.distribution.v1.DelegationDelegatorReward.prototype.setRewardList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cosmos.base.v1beta1.DecCoin=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cosmos.base.v1beta1.DecCoin}
 */
proto.sgn.distribution.v1.DelegationDelegatorReward.prototype.addReward = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cosmos.base.v1beta1.DecCoin, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.distribution.v1.DelegationDelegatorReward} returns this
 */
proto.sgn.distribution.v1.DelegationDelegatorReward.prototype.clearRewardList = function() {
  return this.setRewardList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.distribution.v1.StakingRewardInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.distribution.v1.StakingRewardInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.distribution.v1.StakingRewardInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.StakingRewardInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    cumulativeRewardAmount: (f = msg.getCumulativeRewardAmount()) && cosmos_base_v1beta1_coin_pb.DecCoin.toObject(includeInstance, f),
    claimedRewardAmount: (f = msg.getClaimedRewardAmount()) && cosmos_base_v1beta1_coin_pb.DecCoin.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.distribution.v1.StakingRewardInfo}
 */
proto.sgn.distribution.v1.StakingRewardInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.distribution.v1.StakingRewardInfo;
  return proto.sgn.distribution.v1.StakingRewardInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.distribution.v1.StakingRewardInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.distribution.v1.StakingRewardInfo}
 */
proto.sgn.distribution.v1.StakingRewardInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new cosmos_base_v1beta1_coin_pb.DecCoin;
      reader.readMessage(value,cosmos_base_v1beta1_coin_pb.DecCoin.deserializeBinaryFromReader);
      msg.setCumulativeRewardAmount(value);
      break;
    case 2:
      var value = new cosmos_base_v1beta1_coin_pb.DecCoin;
      reader.readMessage(value,cosmos_base_v1beta1_coin_pb.DecCoin.deserializeBinaryFromReader);
      msg.setClaimedRewardAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.distribution.v1.StakingRewardInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.distribution.v1.StakingRewardInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.distribution.v1.StakingRewardInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.StakingRewardInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCumulativeRewardAmount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      cosmos_base_v1beta1_coin_pb.DecCoin.serializeBinaryToWriter
    );
  }
  f = message.getClaimedRewardAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      cosmos_base_v1beta1_coin_pb.DecCoin.serializeBinaryToWriter
    );
  }
};


/**
 * optional cosmos.base.v1beta1.DecCoin cumulative_reward_amount = 1;
 * @return {?proto.cosmos.base.v1beta1.DecCoin}
 */
proto.sgn.distribution.v1.StakingRewardInfo.prototype.getCumulativeRewardAmount = function() {
  return /** @type{?proto.cosmos.base.v1beta1.DecCoin} */ (
    jspb.Message.getWrapperField(this, cosmos_base_v1beta1_coin_pb.DecCoin, 1));
};


/**
 * @param {?proto.cosmos.base.v1beta1.DecCoin|undefined} value
 * @return {!proto.sgn.distribution.v1.StakingRewardInfo} returns this
*/
proto.sgn.distribution.v1.StakingRewardInfo.prototype.setCumulativeRewardAmount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.distribution.v1.StakingRewardInfo} returns this
 */
proto.sgn.distribution.v1.StakingRewardInfo.prototype.clearCumulativeRewardAmount = function() {
  return this.setCumulativeRewardAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.distribution.v1.StakingRewardInfo.prototype.hasCumulativeRewardAmount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional cosmos.base.v1beta1.DecCoin claimed_reward_amount = 2;
 * @return {?proto.cosmos.base.v1beta1.DecCoin}
 */
proto.sgn.distribution.v1.StakingRewardInfo.prototype.getClaimedRewardAmount = function() {
  return /** @type{?proto.cosmos.base.v1beta1.DecCoin} */ (
    jspb.Message.getWrapperField(this, cosmos_base_v1beta1_coin_pb.DecCoin, 2));
};


/**
 * @param {?proto.cosmos.base.v1beta1.DecCoin|undefined} value
 * @return {!proto.sgn.distribution.v1.StakingRewardInfo} returns this
*/
proto.sgn.distribution.v1.StakingRewardInfo.prototype.setClaimedRewardAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.distribution.v1.StakingRewardInfo} returns this
 */
proto.sgn.distribution.v1.StakingRewardInfo.prototype.clearClaimedRewardAmount = function() {
  return this.setClaimedRewardAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.distribution.v1.StakingRewardInfo.prototype.hasClaimedRewardAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.distribution.v1.StakingRewardClaimInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.distribution.v1.StakingRewardClaimInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    recipient: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastClaimTime: (f = msg.getLastClaimTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    cumulativeRewardAmount: (f = msg.getCumulativeRewardAmount()) && cosmos_base_v1beta1_coin_pb.DecCoin.toObject(includeInstance, f),
    rewardProtoBytes: msg.getRewardProtoBytes_asB64(),
    signaturesList: jspb.Message.toObjectList(msg.getSignaturesList(),
    sgn_common_v1_common_pb.Signature.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.distribution.v1.StakingRewardClaimInfo}
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.distribution.v1.StakingRewardClaimInfo;
  return proto.sgn.distribution.v1.StakingRewardClaimInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.distribution.v1.StakingRewardClaimInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.distribution.v1.StakingRewardClaimInfo}
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecipient(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastClaimTime(value);
      break;
    case 3:
      var value = new cosmos_base_v1beta1_coin_pb.DecCoin;
      reader.readMessage(value,cosmos_base_v1beta1_coin_pb.DecCoin.deserializeBinaryFromReader);
      msg.setCumulativeRewardAmount(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRewardProtoBytes(value);
      break;
    case 5:
      var value = new sgn_common_v1_common_pb.Signature;
      reader.readMessage(value,sgn_common_v1_common_pb.Signature.deserializeBinaryFromReader);
      msg.addSignatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.distribution.v1.StakingRewardClaimInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.distribution.v1.StakingRewardClaimInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecipient();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastClaimTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCumulativeRewardAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      cosmos_base_v1beta1_coin_pb.DecCoin.serializeBinaryToWriter
    );
  }
  f = message.getRewardProtoBytes_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getSignaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      sgn_common_v1_common_pb.Signature.serializeBinaryToWriter
    );
  }
};


/**
 * optional string recipient = 1;
 * @return {string}
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo.prototype.getRecipient = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.distribution.v1.StakingRewardClaimInfo} returns this
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo.prototype.setRecipient = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp last_claim_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo.prototype.getLastClaimTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sgn.distribution.v1.StakingRewardClaimInfo} returns this
*/
proto.sgn.distribution.v1.StakingRewardClaimInfo.prototype.setLastClaimTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.distribution.v1.StakingRewardClaimInfo} returns this
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo.prototype.clearLastClaimTime = function() {
  return this.setLastClaimTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo.prototype.hasLastClaimTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional cosmos.base.v1beta1.DecCoin cumulative_reward_amount = 3;
 * @return {?proto.cosmos.base.v1beta1.DecCoin}
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo.prototype.getCumulativeRewardAmount = function() {
  return /** @type{?proto.cosmos.base.v1beta1.DecCoin} */ (
    jspb.Message.getWrapperField(this, cosmos_base_v1beta1_coin_pb.DecCoin, 3));
};


/**
 * @param {?proto.cosmos.base.v1beta1.DecCoin|undefined} value
 * @return {!proto.sgn.distribution.v1.StakingRewardClaimInfo} returns this
*/
proto.sgn.distribution.v1.StakingRewardClaimInfo.prototype.setCumulativeRewardAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.distribution.v1.StakingRewardClaimInfo} returns this
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo.prototype.clearCumulativeRewardAmount = function() {
  return this.setCumulativeRewardAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo.prototype.hasCumulativeRewardAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes reward_proto_bytes = 4;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo.prototype.getRewardProtoBytes = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes reward_proto_bytes = 4;
 * This is a type-conversion wrapper around `getRewardProtoBytes()`
 * @return {string}
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo.prototype.getRewardProtoBytes_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRewardProtoBytes()));
};


/**
 * optional bytes reward_proto_bytes = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRewardProtoBytes()`
 * @return {!Uint8Array}
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo.prototype.getRewardProtoBytes_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRewardProtoBytes()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.distribution.v1.StakingRewardClaimInfo} returns this
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo.prototype.setRewardProtoBytes = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * repeated sgn.common.v1.Signature signatures = 5;
 * @return {!Array<!proto.sgn.common.v1.Signature>}
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo.prototype.getSignaturesList = function() {
  return /** @type{!Array<!proto.sgn.common.v1.Signature>} */ (
    jspb.Message.getRepeatedWrapperField(this, sgn_common_v1_common_pb.Signature, 5));
};


/**
 * @param {!Array<!proto.sgn.common.v1.Signature>} value
 * @return {!proto.sgn.distribution.v1.StakingRewardClaimInfo} returns this
*/
proto.sgn.distribution.v1.StakingRewardClaimInfo.prototype.setSignaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.sgn.common.v1.Signature=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.common.v1.Signature}
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo.prototype.addSignatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.sgn.common.v1.Signature, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.distribution.v1.StakingRewardClaimInfo} returns this
 */
proto.sgn.distribution.v1.StakingRewardClaimInfo.prototype.clearSignaturesList = function() {
  return this.setSignaturesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.distribution.v1.ClaimableFeesInfo.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.distribution.v1.ClaimableFeesInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.distribution.v1.ClaimableFeesInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.distribution.v1.ClaimableFeesInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.ClaimableFeesInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    claimableFeeAmountsList: jspb.Message.toObjectList(msg.getClaimableFeeAmountsList(),
    cosmos_base_v1beta1_coin_pb.DecCoin.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.distribution.v1.ClaimableFeesInfo}
 */
proto.sgn.distribution.v1.ClaimableFeesInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.distribution.v1.ClaimableFeesInfo;
  return proto.sgn.distribution.v1.ClaimableFeesInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.distribution.v1.ClaimableFeesInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.distribution.v1.ClaimableFeesInfo}
 */
proto.sgn.distribution.v1.ClaimableFeesInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new cosmos_base_v1beta1_coin_pb.DecCoin;
      reader.readMessage(value,cosmos_base_v1beta1_coin_pb.DecCoin.deserializeBinaryFromReader);
      msg.addClaimableFeeAmounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.distribution.v1.ClaimableFeesInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.distribution.v1.ClaimableFeesInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.distribution.v1.ClaimableFeesInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.ClaimableFeesInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClaimableFeeAmountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      cosmos_base_v1beta1_coin_pb.DecCoin.serializeBinaryToWriter
    );
  }
};


/**
 * repeated cosmos.base.v1beta1.DecCoin claimable_fee_amounts = 1;
 * @return {!Array<!proto.cosmos.base.v1beta1.DecCoin>}
 */
proto.sgn.distribution.v1.ClaimableFeesInfo.prototype.getClaimableFeeAmountsList = function() {
  return /** @type{!Array<!proto.cosmos.base.v1beta1.DecCoin>} */ (
    jspb.Message.getRepeatedWrapperField(this, cosmos_base_v1beta1_coin_pb.DecCoin, 1));
};


/**
 * @param {!Array<!proto.cosmos.base.v1beta1.DecCoin>} value
 * @return {!proto.sgn.distribution.v1.ClaimableFeesInfo} returns this
*/
proto.sgn.distribution.v1.ClaimableFeesInfo.prototype.setClaimableFeeAmountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cosmos.base.v1beta1.DecCoin=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cosmos.base.v1beta1.DecCoin}
 */
proto.sgn.distribution.v1.ClaimableFeesInfo.prototype.addClaimableFeeAmounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cosmos.base.v1beta1.DecCoin, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.distribution.v1.ClaimableFeesInfo} returns this
 */
proto.sgn.distribution.v1.ClaimableFeesInfo.prototype.clearClaimableFeeAmountsList = function() {
  return this.setClaimableFeeAmountsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.distribution.v1.StakingRewardOnChain.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.distribution.v1.StakingRewardOnChain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.distribution.v1.StakingRewardOnChain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.StakingRewardOnChain.toObject = function(includeInstance, msg) {
  var f, obj = {
    recipient: msg.getRecipient_asB64(),
    cumulativeRewardAmount: msg.getCumulativeRewardAmount_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.distribution.v1.StakingRewardOnChain}
 */
proto.sgn.distribution.v1.StakingRewardOnChain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.distribution.v1.StakingRewardOnChain;
  return proto.sgn.distribution.v1.StakingRewardOnChain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.distribution.v1.StakingRewardOnChain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.distribution.v1.StakingRewardOnChain}
 */
proto.sgn.distribution.v1.StakingRewardOnChain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRecipient(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCumulativeRewardAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.distribution.v1.StakingRewardOnChain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.distribution.v1.StakingRewardOnChain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.distribution.v1.StakingRewardOnChain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.StakingRewardOnChain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecipient_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getCumulativeRewardAmount_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional bytes recipient = 1;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.distribution.v1.StakingRewardOnChain.prototype.getRecipient = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes recipient = 1;
 * This is a type-conversion wrapper around `getRecipient()`
 * @return {string}
 */
proto.sgn.distribution.v1.StakingRewardOnChain.prototype.getRecipient_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRecipient()));
};


/**
 * optional bytes recipient = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRecipient()`
 * @return {!Uint8Array}
 */
proto.sgn.distribution.v1.StakingRewardOnChain.prototype.getRecipient_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRecipient()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.distribution.v1.StakingRewardOnChain} returns this
 */
proto.sgn.distribution.v1.StakingRewardOnChain.prototype.setRecipient = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes cumulative_reward_amount = 2;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.distribution.v1.StakingRewardOnChain.prototype.getCumulativeRewardAmount = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes cumulative_reward_amount = 2;
 * This is a type-conversion wrapper around `getCumulativeRewardAmount()`
 * @return {string}
 */
proto.sgn.distribution.v1.StakingRewardOnChain.prototype.getCumulativeRewardAmount_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCumulativeRewardAmount()));
};


/**
 * optional bytes cumulative_reward_amount = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCumulativeRewardAmount()`
 * @return {!Uint8Array}
 */
proto.sgn.distribution.v1.StakingRewardOnChain.prototype.getCumulativeRewardAmount_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCumulativeRewardAmount()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.distribution.v1.StakingRewardOnChain} returns this
 */
proto.sgn.distribution.v1.StakingRewardOnChain.prototype.setCumulativeRewardAmount = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    recipient: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 4, ""),
    deposit: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit}
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit;
  return proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit}
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecipient(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeposit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRecipient();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeposit();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit} returns this
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit} returns this
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string recipient = 3;
 * @return {string}
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit.prototype.getRecipient = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit} returns this
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit.prototype.setRecipient = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string amount = 4;
 * @return {string}
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit} returns this
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string deposit = 5;
 * @return {string}
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit.prototype.getDeposit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit} returns this
 */
proto.sgn.distribution.v1.CommunityPoolSpendProposalWithDeposit.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


goog.object.extend(exports, proto.sgn.distribution.v1);
