// source: sgn/message/v1/message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var cosmos_proto_cosmos_pb = require('../../../cosmos_proto/cosmos_pb.js');
goog.object.extend(proto, cosmos_proto_cosmos_pb);
var cosmos_base_v1beta1_coin_pb = require('../../../cosmos/base/v1beta1/coin_pb.js');
goog.object.extend(proto, cosmos_base_v1beta1_coin_pb);
var gogoproto_gogo_pb = require('../../../gogoproto/gogo_pb.js');
goog.object.extend(proto, gogoproto_gogo_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var sgn_common_v1_common_pb = require('../../../sgn/common/v1/common_pb.js');
goog.object.extend(proto, sgn_common_v1_common_pb);
goog.exportSymbol('proto.sgn.message.v1.ExecutionContext', null, global);
goog.exportSymbol('proto.sgn.message.v1.ExecutionStatus', null, global);
goog.exportSymbol('proto.sgn.message.v1.FeeClaimDetails', null, global);
goog.exportSymbol('proto.sgn.message.v1.FeeClaimInfo', null, global);
goog.exportSymbol('proto.sgn.message.v1.Message', null, global);
goog.exportSymbol('proto.sgn.message.v1.MessageBusInfo', null, global);
goog.exportSymbol('proto.sgn.message.v1.MsgProposal', null, global);
goog.exportSymbol('proto.sgn.message.v1.MsgType', null, global);
goog.exportSymbol('proto.sgn.message.v1.Params', null, global);
goog.exportSymbol('proto.sgn.message.v1.Transfer', null, global);
goog.exportSymbol('proto.sgn.message.v1.TransferType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.message.v1.Params = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.message.v1.Params, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.message.v1.Params.displayName = 'proto.sgn.message.v1.Params';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.message.v1.Message = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.message.v1.Message.repeatedFields_, null);
};
goog.inherits(proto.sgn.message.v1.Message, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.message.v1.Message.displayName = 'proto.sgn.message.v1.Message';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.message.v1.Transfer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.message.v1.Transfer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.message.v1.Transfer.displayName = 'proto.sgn.message.v1.Transfer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.message.v1.ExecutionContext = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.message.v1.ExecutionContext.repeatedFields_, null);
};
goog.inherits(proto.sgn.message.v1.ExecutionContext, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.message.v1.ExecutionContext.displayName = 'proto.sgn.message.v1.ExecutionContext';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.message.v1.FeeClaimDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.message.v1.FeeClaimDetails.repeatedFields_, null);
};
goog.inherits(proto.sgn.message.v1.FeeClaimDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.message.v1.FeeClaimDetails.displayName = 'proto.sgn.message.v1.FeeClaimDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.message.v1.FeeClaimInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.message.v1.FeeClaimInfo.repeatedFields_, null);
};
goog.inherits(proto.sgn.message.v1.FeeClaimInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.message.v1.FeeClaimInfo.displayName = 'proto.sgn.message.v1.FeeClaimInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.message.v1.MessageBusInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.message.v1.MessageBusInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.message.v1.MessageBusInfo.displayName = 'proto.sgn.message.v1.MessageBusInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.message.v1.MsgProposal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.message.v1.MsgProposal.repeatedFields_, null);
};
goog.inherits(proto.sgn.message.v1.MsgProposal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.message.v1.MsgProposal.displayName = 'proto.sgn.message.v1.MsgProposal';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.message.v1.Params.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.message.v1.Params.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.message.v1.Params} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.message.v1.Params.toObject = function(includeInstance, msg) {
  var f, obj = {
    triggerSignCooldown: (f = msg.getTriggerSignCooldown()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.message.v1.Params}
 */
proto.sgn.message.v1.Params.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.message.v1.Params;
  return proto.sgn.message.v1.Params.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.message.v1.Params} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.message.v1.Params}
 */
proto.sgn.message.v1.Params.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setTriggerSignCooldown(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.message.v1.Params.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.message.v1.Params.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.message.v1.Params} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.message.v1.Params.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTriggerSignCooldown();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Duration trigger_sign_cooldown = 1;
 * @return {?proto.google.protobuf.Duration}
 */
proto.sgn.message.v1.Params.prototype.getTriggerSignCooldown = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 1));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.sgn.message.v1.Params} returns this
*/
proto.sgn.message.v1.Params.prototype.setTriggerSignCooldown = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.message.v1.Params} returns this
 */
proto.sgn.message.v1.Params.prototype.clearTriggerSignCooldown = function() {
  return this.setTriggerSignCooldown(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.message.v1.Params.prototype.hasTriggerSignCooldown = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.message.v1.Message.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.message.v1.Message.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.message.v1.Message.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.message.v1.Message} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.message.v1.Message.toObject = function(includeInstance, msg) {
  var f, obj = {
    srcChainId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sender: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dstChainId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    receiver: jspb.Message.getFieldWithDefault(msg, 4, ""),
    data: msg.getData_asB64(),
    fee: jspb.Message.getFieldWithDefault(msg, 6, ""),
    transferType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    executionStatus: jspb.Message.getFieldWithDefault(msg, 8, 0),
    signaturesList: jspb.Message.toObjectList(msg.getSignaturesList(),
    sgn_common_v1_common_pb.Signature.toObject, includeInstance),
    lastSigReqTime: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.message.v1.Message}
 */
proto.sgn.message.v1.Message.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.message.v1.Message;
  return proto.sgn.message.v1.Message.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.message.v1.Message} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.message.v1.Message}
 */
proto.sgn.message.v1.Message.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSrcChainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSender(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDstChainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiver(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFee(value);
      break;
    case 7:
      var value = /** @type {!proto.sgn.message.v1.TransferType} */ (reader.readEnum());
      msg.setTransferType(value);
      break;
    case 8:
      var value = /** @type {!proto.sgn.message.v1.ExecutionStatus} */ (reader.readEnum());
      msg.setExecutionStatus(value);
      break;
    case 9:
      var value = new sgn_common_v1_common_pb.Signature;
      reader.readMessage(value,sgn_common_v1_common_pb.Signature.deserializeBinaryFromReader);
      msg.addSignatures(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastSigReqTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.message.v1.Message.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.message.v1.Message.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.message.v1.Message} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.message.v1.Message.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSrcChainId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getSender();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDstChainId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getReceiver();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = message.getFee();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTransferType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getExecutionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getSignaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      sgn_common_v1_common_pb.Signature.serializeBinaryToWriter
    );
  }
  f = message.getLastSigReqTime();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * optional uint64 src_chain_id = 1;
 * @return {number}
 */
proto.sgn.message.v1.Message.prototype.getSrcChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.message.v1.Message} returns this
 */
proto.sgn.message.v1.Message.prototype.setSrcChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string sender = 2;
 * @return {string}
 */
proto.sgn.message.v1.Message.prototype.getSender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.message.v1.Message} returns this
 */
proto.sgn.message.v1.Message.prototype.setSender = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 dst_chain_id = 3;
 * @return {number}
 */
proto.sgn.message.v1.Message.prototype.getDstChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.message.v1.Message} returns this
 */
proto.sgn.message.v1.Message.prototype.setDstChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string receiver = 4;
 * @return {string}
 */
proto.sgn.message.v1.Message.prototype.getReceiver = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.message.v1.Message} returns this
 */
proto.sgn.message.v1.Message.prototype.setReceiver = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bytes data = 5;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.message.v1.Message.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes data = 5;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.sgn.message.v1.Message.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.sgn.message.v1.Message.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.message.v1.Message} returns this
 */
proto.sgn.message.v1.Message.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};


/**
 * optional string fee = 6;
 * @return {string}
 */
proto.sgn.message.v1.Message.prototype.getFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.message.v1.Message} returns this
 */
proto.sgn.message.v1.Message.prototype.setFee = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional TransferType transfer_type = 7;
 * @return {!proto.sgn.message.v1.TransferType}
 */
proto.sgn.message.v1.Message.prototype.getTransferType = function() {
  return /** @type {!proto.sgn.message.v1.TransferType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.sgn.message.v1.TransferType} value
 * @return {!proto.sgn.message.v1.Message} returns this
 */
proto.sgn.message.v1.Message.prototype.setTransferType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional ExecutionStatus execution_status = 8;
 * @return {!proto.sgn.message.v1.ExecutionStatus}
 */
proto.sgn.message.v1.Message.prototype.getExecutionStatus = function() {
  return /** @type {!proto.sgn.message.v1.ExecutionStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.sgn.message.v1.ExecutionStatus} value
 * @return {!proto.sgn.message.v1.Message} returns this
 */
proto.sgn.message.v1.Message.prototype.setExecutionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated sgn.common.v1.Signature signatures = 9;
 * @return {!Array<!proto.sgn.common.v1.Signature>}
 */
proto.sgn.message.v1.Message.prototype.getSignaturesList = function() {
  return /** @type{!Array<!proto.sgn.common.v1.Signature>} */ (
    jspb.Message.getRepeatedWrapperField(this, sgn_common_v1_common_pb.Signature, 9));
};


/**
 * @param {!Array<!proto.sgn.common.v1.Signature>} value
 * @return {!proto.sgn.message.v1.Message} returns this
*/
proto.sgn.message.v1.Message.prototype.setSignaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.sgn.common.v1.Signature=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.common.v1.Signature}
 */
proto.sgn.message.v1.Message.prototype.addSignatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.sgn.common.v1.Signature, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.message.v1.Message} returns this
 */
proto.sgn.message.v1.Message.prototype.clearSignaturesList = function() {
  return this.setSignaturesList([]);
};


/**
 * optional int64 last_sig_req_time = 10;
 * @return {number}
 */
proto.sgn.message.v1.Message.prototype.getLastSigReqTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.message.v1.Message} returns this
 */
proto.sgn.message.v1.Message.prototype.setLastSigReqTime = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.message.v1.Transfer.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.message.v1.Transfer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.message.v1.Transfer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.message.v1.Transfer.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: msg.getToken_asB64(),
    amount: jspb.Message.getFieldWithDefault(msg, 2, ""),
    seqNum: jspb.Message.getFieldWithDefault(msg, 3, 0),
    refId: msg.getRefId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.message.v1.Transfer}
 */
proto.sgn.message.v1.Transfer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.message.v1.Transfer;
  return proto.sgn.message.v1.Transfer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.message.v1.Transfer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.message.v1.Transfer}
 */
proto.sgn.message.v1.Transfer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSeqNum(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRefId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.message.v1.Transfer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.message.v1.Transfer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.message.v1.Transfer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.message.v1.Transfer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSeqNum();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getRefId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional bytes token = 1;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.message.v1.Transfer.prototype.getToken = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes token = 1;
 * This is a type-conversion wrapper around `getToken()`
 * @return {string}
 */
proto.sgn.message.v1.Transfer.prototype.getToken_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getToken()));
};


/**
 * optional bytes token = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getToken()`
 * @return {!Uint8Array}
 */
proto.sgn.message.v1.Transfer.prototype.getToken_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getToken()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.message.v1.Transfer} returns this
 */
proto.sgn.message.v1.Transfer.prototype.setToken = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string amount = 2;
 * @return {string}
 */
proto.sgn.message.v1.Transfer.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.message.v1.Transfer} returns this
 */
proto.sgn.message.v1.Transfer.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 seq_num = 3;
 * @return {number}
 */
proto.sgn.message.v1.Transfer.prototype.getSeqNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.message.v1.Transfer} returns this
 */
proto.sgn.message.v1.Transfer.prototype.setSeqNum = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bytes ref_id = 4;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.message.v1.Transfer.prototype.getRefId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes ref_id = 4;
 * This is a type-conversion wrapper around `getRefId()`
 * @return {string}
 */
proto.sgn.message.v1.Transfer.prototype.getRefId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRefId()));
};


/**
 * optional bytes ref_id = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRefId()`
 * @return {!Uint8Array}
 */
proto.sgn.message.v1.Transfer.prototype.getRefId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRefId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.message.v1.Transfer} returns this
 */
proto.sgn.message.v1.Transfer.prototype.setRefId = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.message.v1.ExecutionContext.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.message.v1.ExecutionContext.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.message.v1.ExecutionContext.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.message.v1.ExecutionContext} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.message.v1.ExecutionContext.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageId: msg.getMessageId_asB64(),
    message: (f = msg.getMessage()) && proto.sgn.message.v1.Message.toObject(includeInstance, f),
    transfer: (f = msg.getTransfer()) && proto.sgn.message.v1.Transfer.toObject(includeInstance, f),
    powersList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.message.v1.ExecutionContext}
 */
proto.sgn.message.v1.ExecutionContext.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.message.v1.ExecutionContext;
  return proto.sgn.message.v1.ExecutionContext.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.message.v1.ExecutionContext} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.message.v1.ExecutionContext}
 */
proto.sgn.message.v1.ExecutionContext.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMessageId(value);
      break;
    case 2:
      var value = new proto.sgn.message.v1.Message;
      reader.readMessage(value,proto.sgn.message.v1.Message.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.sgn.message.v1.Transfer;
      reader.readMessage(value,proto.sgn.message.v1.Transfer.deserializeBinaryFromReader);
      msg.setTransfer(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addPowers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.message.v1.ExecutionContext.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.message.v1.ExecutionContext.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.message.v1.ExecutionContext} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.message.v1.ExecutionContext.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sgn.message.v1.Message.serializeBinaryToWriter
    );
  }
  f = message.getTransfer();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sgn.message.v1.Transfer.serializeBinaryToWriter
    );
  }
  f = message.getPowersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional bytes message_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.message.v1.ExecutionContext.prototype.getMessageId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes message_id = 1;
 * This is a type-conversion wrapper around `getMessageId()`
 * @return {string}
 */
proto.sgn.message.v1.ExecutionContext.prototype.getMessageId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMessageId()));
};


/**
 * optional bytes message_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMessageId()`
 * @return {!Uint8Array}
 */
proto.sgn.message.v1.ExecutionContext.prototype.getMessageId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMessageId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.message.v1.ExecutionContext} returns this
 */
proto.sgn.message.v1.ExecutionContext.prototype.setMessageId = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional Message message = 2;
 * @return {?proto.sgn.message.v1.Message}
 */
proto.sgn.message.v1.ExecutionContext.prototype.getMessage = function() {
  return /** @type{?proto.sgn.message.v1.Message} */ (
    jspb.Message.getWrapperField(this, proto.sgn.message.v1.Message, 2));
};


/**
 * @param {?proto.sgn.message.v1.Message|undefined} value
 * @return {!proto.sgn.message.v1.ExecutionContext} returns this
*/
proto.sgn.message.v1.ExecutionContext.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.message.v1.ExecutionContext} returns this
 */
proto.sgn.message.v1.ExecutionContext.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.message.v1.ExecutionContext.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Transfer transfer = 3;
 * @return {?proto.sgn.message.v1.Transfer}
 */
proto.sgn.message.v1.ExecutionContext.prototype.getTransfer = function() {
  return /** @type{?proto.sgn.message.v1.Transfer} */ (
    jspb.Message.getWrapperField(this, proto.sgn.message.v1.Transfer, 3));
};


/**
 * @param {?proto.sgn.message.v1.Transfer|undefined} value
 * @return {!proto.sgn.message.v1.ExecutionContext} returns this
*/
proto.sgn.message.v1.ExecutionContext.prototype.setTransfer = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.message.v1.ExecutionContext} returns this
 */
proto.sgn.message.v1.ExecutionContext.prototype.clearTransfer = function() {
  return this.setTransfer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.message.v1.ExecutionContext.prototype.hasTransfer = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string powers = 4;
 * @return {!Array<string>}
 */
proto.sgn.message.v1.ExecutionContext.prototype.getPowersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sgn.message.v1.ExecutionContext} returns this
 */
proto.sgn.message.v1.ExecutionContext.prototype.setPowersList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sgn.message.v1.ExecutionContext} returns this
 */
proto.sgn.message.v1.ExecutionContext.prototype.addPowers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.message.v1.ExecutionContext} returns this
 */
proto.sgn.message.v1.ExecutionContext.prototype.clearPowersList = function() {
  return this.setPowersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.message.v1.FeeClaimDetails.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.message.v1.FeeClaimDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.message.v1.FeeClaimDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.message.v1.FeeClaimDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.message.v1.FeeClaimDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    chainId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cumulativeFeeAmount: (f = msg.getCumulativeFeeAmount()) && cosmos_base_v1beta1_coin_pb.DecCoin.toObject(includeInstance, f),
    signaturesList: jspb.Message.toObjectList(msg.getSignaturesList(),
    sgn_common_v1_common_pb.Signature.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.message.v1.FeeClaimDetails}
 */
proto.sgn.message.v1.FeeClaimDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.message.v1.FeeClaimDetails;
  return proto.sgn.message.v1.FeeClaimDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.message.v1.FeeClaimDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.message.v1.FeeClaimDetails}
 */
proto.sgn.message.v1.FeeClaimDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChainId(value);
      break;
    case 2:
      var value = new cosmos_base_v1beta1_coin_pb.DecCoin;
      reader.readMessage(value,cosmos_base_v1beta1_coin_pb.DecCoin.deserializeBinaryFromReader);
      msg.setCumulativeFeeAmount(value);
      break;
    case 3:
      var value = new sgn_common_v1_common_pb.Signature;
      reader.readMessage(value,sgn_common_v1_common_pb.Signature.deserializeBinaryFromReader);
      msg.addSignatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.message.v1.FeeClaimDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.message.v1.FeeClaimDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.message.v1.FeeClaimDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.message.v1.FeeClaimDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChainId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getCumulativeFeeAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      cosmos_base_v1beta1_coin_pb.DecCoin.serializeBinaryToWriter
    );
  }
  f = message.getSignaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      sgn_common_v1_common_pb.Signature.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 chain_id = 1;
 * @return {number}
 */
proto.sgn.message.v1.FeeClaimDetails.prototype.getChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.message.v1.FeeClaimDetails} returns this
 */
proto.sgn.message.v1.FeeClaimDetails.prototype.setChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional cosmos.base.v1beta1.DecCoin cumulative_fee_amount = 2;
 * @return {?proto.cosmos.base.v1beta1.DecCoin}
 */
proto.sgn.message.v1.FeeClaimDetails.prototype.getCumulativeFeeAmount = function() {
  return /** @type{?proto.cosmos.base.v1beta1.DecCoin} */ (
    jspb.Message.getWrapperField(this, cosmos_base_v1beta1_coin_pb.DecCoin, 2));
};


/**
 * @param {?proto.cosmos.base.v1beta1.DecCoin|undefined} value
 * @return {!proto.sgn.message.v1.FeeClaimDetails} returns this
*/
proto.sgn.message.v1.FeeClaimDetails.prototype.setCumulativeFeeAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.message.v1.FeeClaimDetails} returns this
 */
proto.sgn.message.v1.FeeClaimDetails.prototype.clearCumulativeFeeAmount = function() {
  return this.setCumulativeFeeAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.message.v1.FeeClaimDetails.prototype.hasCumulativeFeeAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated sgn.common.v1.Signature signatures = 3;
 * @return {!Array<!proto.sgn.common.v1.Signature>}
 */
proto.sgn.message.v1.FeeClaimDetails.prototype.getSignaturesList = function() {
  return /** @type{!Array<!proto.sgn.common.v1.Signature>} */ (
    jspb.Message.getRepeatedWrapperField(this, sgn_common_v1_common_pb.Signature, 3));
};


/**
 * @param {!Array<!proto.sgn.common.v1.Signature>} value
 * @return {!proto.sgn.message.v1.FeeClaimDetails} returns this
*/
proto.sgn.message.v1.FeeClaimDetails.prototype.setSignaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sgn.common.v1.Signature=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.common.v1.Signature}
 */
proto.sgn.message.v1.FeeClaimDetails.prototype.addSignatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sgn.common.v1.Signature, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.message.v1.FeeClaimDetails} returns this
 */
proto.sgn.message.v1.FeeClaimDetails.prototype.clearSignaturesList = function() {
  return this.setSignaturesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.message.v1.FeeClaimInfo.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.message.v1.FeeClaimInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.message.v1.FeeClaimInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.message.v1.FeeClaimInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.message.v1.FeeClaimInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    recipient: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastClaimTime: (f = msg.getLastClaimTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    feeClaimDetailsListList: jspb.Message.toObjectList(msg.getFeeClaimDetailsListList(),
    proto.sgn.message.v1.FeeClaimDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.message.v1.FeeClaimInfo}
 */
proto.sgn.message.v1.FeeClaimInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.message.v1.FeeClaimInfo;
  return proto.sgn.message.v1.FeeClaimInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.message.v1.FeeClaimInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.message.v1.FeeClaimInfo}
 */
proto.sgn.message.v1.FeeClaimInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecipient(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastClaimTime(value);
      break;
    case 3:
      var value = new proto.sgn.message.v1.FeeClaimDetails;
      reader.readMessage(value,proto.sgn.message.v1.FeeClaimDetails.deserializeBinaryFromReader);
      msg.addFeeClaimDetailsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.message.v1.FeeClaimInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.message.v1.FeeClaimInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.message.v1.FeeClaimInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.message.v1.FeeClaimInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecipient();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastClaimTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFeeClaimDetailsListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sgn.message.v1.FeeClaimDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string recipient = 1;
 * @return {string}
 */
proto.sgn.message.v1.FeeClaimInfo.prototype.getRecipient = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.message.v1.FeeClaimInfo} returns this
 */
proto.sgn.message.v1.FeeClaimInfo.prototype.setRecipient = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp last_claim_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sgn.message.v1.FeeClaimInfo.prototype.getLastClaimTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sgn.message.v1.FeeClaimInfo} returns this
*/
proto.sgn.message.v1.FeeClaimInfo.prototype.setLastClaimTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.message.v1.FeeClaimInfo} returns this
 */
proto.sgn.message.v1.FeeClaimInfo.prototype.clearLastClaimTime = function() {
  return this.setLastClaimTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.message.v1.FeeClaimInfo.prototype.hasLastClaimTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated FeeClaimDetails fee_claim_details_list = 3;
 * @return {!Array<!proto.sgn.message.v1.FeeClaimDetails>}
 */
proto.sgn.message.v1.FeeClaimInfo.prototype.getFeeClaimDetailsListList = function() {
  return /** @type{!Array<!proto.sgn.message.v1.FeeClaimDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sgn.message.v1.FeeClaimDetails, 3));
};


/**
 * @param {!Array<!proto.sgn.message.v1.FeeClaimDetails>} value
 * @return {!proto.sgn.message.v1.FeeClaimInfo} returns this
*/
proto.sgn.message.v1.FeeClaimInfo.prototype.setFeeClaimDetailsListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sgn.message.v1.FeeClaimDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.message.v1.FeeClaimDetails}
 */
proto.sgn.message.v1.FeeClaimInfo.prototype.addFeeClaimDetailsList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sgn.message.v1.FeeClaimDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.message.v1.FeeClaimInfo} returns this
 */
proto.sgn.message.v1.FeeClaimInfo.prototype.clearFeeClaimDetailsListList = function() {
  return this.setFeeClaimDetailsListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.message.v1.MessageBusInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.message.v1.MessageBusInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.message.v1.MessageBusInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.message.v1.MessageBusInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractInfo: (f = msg.getContractInfo()) && sgn_common_v1_common_pb.ContractInfo.toObject(includeInstance, f),
    feeTokenSymbol: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.message.v1.MessageBusInfo}
 */
proto.sgn.message.v1.MessageBusInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.message.v1.MessageBusInfo;
  return proto.sgn.message.v1.MessageBusInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.message.v1.MessageBusInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.message.v1.MessageBusInfo}
 */
proto.sgn.message.v1.MessageBusInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sgn_common_v1_common_pb.ContractInfo;
      reader.readMessage(value,sgn_common_v1_common_pb.ContractInfo.deserializeBinaryFromReader);
      msg.setContractInfo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeTokenSymbol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.message.v1.MessageBusInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.message.v1.MessageBusInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.message.v1.MessageBusInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.message.v1.MessageBusInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sgn_common_v1_common_pb.ContractInfo.serializeBinaryToWriter
    );
  }
  f = message.getFeeTokenSymbol();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional sgn.common.v1.ContractInfo contract_info = 1;
 * @return {?proto.sgn.common.v1.ContractInfo}
 */
proto.sgn.message.v1.MessageBusInfo.prototype.getContractInfo = function() {
  return /** @type{?proto.sgn.common.v1.ContractInfo} */ (
    jspb.Message.getWrapperField(this, sgn_common_v1_common_pb.ContractInfo, 1));
};


/**
 * @param {?proto.sgn.common.v1.ContractInfo|undefined} value
 * @return {!proto.sgn.message.v1.MessageBusInfo} returns this
*/
proto.sgn.message.v1.MessageBusInfo.prototype.setContractInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.message.v1.MessageBusInfo} returns this
 */
proto.sgn.message.v1.MessageBusInfo.prototype.clearContractInfo = function() {
  return this.setContractInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.message.v1.MessageBusInfo.prototype.hasContractInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string fee_token_symbol = 2;
 * @return {string}
 */
proto.sgn.message.v1.MessageBusInfo.prototype.getFeeTokenSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.message.v1.MessageBusInfo} returns this
 */
proto.sgn.message.v1.MessageBusInfo.prototype.setFeeTokenSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.message.v1.MsgProposal.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.message.v1.MsgProposal.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.message.v1.MsgProposal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.message.v1.MsgProposal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.message.v1.MsgProposal.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    messageBusesList: jspb.Message.toObjectList(msg.getMessageBusesList(),
    proto.sgn.message.v1.MessageBusInfo.toObject, includeInstance),
    deposit: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.message.v1.MsgProposal}
 */
proto.sgn.message.v1.MsgProposal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.message.v1.MsgProposal;
  return proto.sgn.message.v1.MsgProposal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.message.v1.MsgProposal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.message.v1.MsgProposal}
 */
proto.sgn.message.v1.MsgProposal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new proto.sgn.message.v1.MessageBusInfo;
      reader.readMessage(value,proto.sgn.message.v1.MessageBusInfo.deserializeBinaryFromReader);
      msg.addMessageBuses(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeposit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.message.v1.MsgProposal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.message.v1.MsgProposal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.message.v1.MsgProposal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.message.v1.MsgProposal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessageBusesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sgn.message.v1.MessageBusInfo.serializeBinaryToWriter
    );
  }
  f = message.getDeposit();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.sgn.message.v1.MsgProposal.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.message.v1.MsgProposal} returns this
 */
proto.sgn.message.v1.MsgProposal.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.sgn.message.v1.MsgProposal.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.message.v1.MsgProposal} returns this
 */
proto.sgn.message.v1.MsgProposal.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated MessageBusInfo message_buses = 3;
 * @return {!Array<!proto.sgn.message.v1.MessageBusInfo>}
 */
proto.sgn.message.v1.MsgProposal.prototype.getMessageBusesList = function() {
  return /** @type{!Array<!proto.sgn.message.v1.MessageBusInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sgn.message.v1.MessageBusInfo, 3));
};


/**
 * @param {!Array<!proto.sgn.message.v1.MessageBusInfo>} value
 * @return {!proto.sgn.message.v1.MsgProposal} returns this
*/
proto.sgn.message.v1.MsgProposal.prototype.setMessageBusesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sgn.message.v1.MessageBusInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.message.v1.MessageBusInfo}
 */
proto.sgn.message.v1.MsgProposal.prototype.addMessageBuses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sgn.message.v1.MessageBusInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.message.v1.MsgProposal} returns this
 */
proto.sgn.message.v1.MsgProposal.prototype.clearMessageBusesList = function() {
  return this.setMessageBusesList([]);
};


/**
 * optional string deposit = 4;
 * @return {string}
 */
proto.sgn.message.v1.MsgProposal.prototype.getDeposit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.message.v1.MsgProposal} returns this
 */
proto.sgn.message.v1.MsgProposal.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.sgn.message.v1.TransferType = {
  TRANSFER_TYPE_NULL: 0,
  TRANSFER_TYPE_LIQUIDITY_SEND: 1,
  TRANSFER_TYPE_LIQUIDITY_WITHDRAW: 2,
  TRANSFER_TYPE_PEG_MINT: 3,
  TRANSFER_TYPE_PEG_WITHDRAW: 4
};

/**
 * @enum {number}
 */
proto.sgn.message.v1.ExecutionStatus = {
  EXECUTION_STATUS_PENDING: 0,
  EXECUTION_STATUS_SUCCESS: 1,
  EXECUTION_STATUS_FAILURE: 2,
  EXECUTION_STATUS_FALLBACK: 3
};

/**
 * @enum {number}
 */
proto.sgn.message.v1.MsgType = {
  MSG_TYPE_MESSAGE_WITH_TRANSFER: 0,
  MSG_TYPE_MESSAGE: 1
};

goog.object.extend(exports, proto.sgn.message.v1);
